import { DatePicker, Image, Modal, Select, Spin, notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import UploadSelfie from "./UploadSelfie";
import axios from "axios";
import { db, storage } from "../Firebase";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { BASE_URI } from "../BASE_URI";
import { DropdownDate } from "react-dropdown-date";
import imageCompression from "browser-image-compression";

export default function ClientsInputForm() {
  const [cover_price, setCover_price] = useState(0);

  let addClient = async (e) => {
    e.preventDefault();

    let payload = [];

    if (pack == "Myself only [60/day or 420/week]") {
      setCover_price(4200);
      payload.push({
        name: name,
        relationship: "Myself",
      });
    } else if (pack == "Me and 1 Other Member [85/day or 595/week]") {
      setCover_price(595);
      payload.push({
        name: one_member_pack_name,
        relationship: one_member_pack_relationship,
      });
    } else if (pack == "Me and 2 Other Members [100/day or 700/week]") {
      setCover_price(700);
      payload.push({
        name: two_member_pack_name,
        relationship: two_member_pack_relationship,
      });
      payload.push({
        name: two_member_pack_name2,
        relationship: two_member_pack_relationship2,
      });
    } else if (pack == "Me and 3 Other Membes [120/day or 840/week]") {
      setCover_price(840);
      payload.push({
        name: three_member_pack_name,
        relationship: three_member_pack_relationship,
      });
      payload.push({
        name: three_member_pack_name2,
        relationship: three_member_pack_relationship2,
      });
      payload.push({
        name: three_member_pack_name3,
        relationship: three_member_pack_relationship3,
      });
    } else if (pack == "Me and 4 Other Members [130/day or 920/week]") {
      setCover_price(920);

      payload.push({
        name: four_member_pack_name,
        relationship: four_member_pack_relationship,
      });
      payload.push({
        name: four_member_pack_name2,
        relationship: four_member_pack_relationship2,
      });
      payload.push({
        name: four_member_pack_name3,
        relationship: four_member_pack_relationship3,
      });
      payload.push({
        name: four_member_pack_name4,
        relationship: four_member_pack_relationship4,
      });
    } else if (pack == "Me and 5 Other Members [140/day or 980/week]") {
      setCover_price(980);
      payload.push({
        name: five_member_pack_name,
        relationship: five_member_pack_relationship,
      });
      payload.push({
        name: five_member_pack_name2,
        relationship: five_member_pack_relationship2,
      });
      payload.push({
        name: five_member_pack_name3,
        relationship: five_member_pack_relationship3,
      });
      payload.push({
        name: five_member_pack_name4,
        relationship: five_member_pack_relationship4,
      });
      payload.push({
        name: five_member_pack_name5,
        relationship: five_member_pack_relationship5,
      });
    }

    // console.log(payload);

    // return;

    //
    if (name == "") {
      openErrorNotification("Kindly Provide your Full Name to proceed");
      return;
    }
    if (idNo == "") {
      openErrorNotification(
        "Kindly Provide your National ID number to proceed"
      );
      return;
    }

    if (phone == "") {
      openErrorNotification("Kindly Provide your phone number to proceed");
      return;
    }

    if (selectedDate == "") {
      openErrorNotification("Kindly Provide your Date Of Birth to proceed");
      return;
    }
    if (gender == "") {
      openErrorNotification("Kindly Provide your gender to proceed");
      return;
    }
    if (town == "") {
      openErrorNotification("Kindly Provide your town to proceed");
      return;
    }
    if (route == "") {
      openErrorNotification("Kindly Provide your route to proceed");
      return;
    }
    if (sacco == "") {
      openErrorNotification("Kindly Provide your Sacco to proceed");
      return;
    }
    if (route_code == "") {
      openErrorNotification("Kindly Provide your Route Code to proceed");
      return;
    }
    if (kinName == "") {
      openErrorNotification("Kindly Provide your Next of Kin Name to proceed");
      return;
    }
    if (kinContact == "") {
      openErrorNotification(
        "Kindly Provide your Next of Kin Contact to proceed"
      );
      return;
    }
    if (physicalAdress == "") {
      openErrorNotification("Kindly Provide your Physical Address  to proceed");
      return;
    }
    if (selfie == undefined) {
      openErrorNotification(
        "We are unable to pick your selfie upload. Check the image size or type. we accept jpg, jpeg, and png"
      );
      return;
    }
    if (idfront == undefined) {
      openErrorNotification(
        "We are unable to pick your front ID picture upload. Check the image size or type. we accept jpg, jpeg, and png"
      );
      return;
    }
    if (idback == undefined) {
      openErrorNotification(
        "We are unable to pick your Back ID picture upload. Check the image size or type. we accept jpg, jpeg, and png"
      );
    }

    // setLoading(true);
    setModal(true);
    setLoading(true);

    // try compressing

    // async function handleImageUpload(file) {
    //   const imageFile = file;
    //   console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    //   console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    //   const options = {
    //     maxSizeMB: 0.2,
    //     maxWidthOrHeight: 1920,
    //     useWebWorker: true,
    //   };
    //   try {
    //     const compressedFile = await imageCompression(imageFile, options);
    //     console.log(
    //       "compressedFile instanceof Blob",
    //       compressedFile instanceof Blob
    //     ); // true
    //     console.log(
    //       `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
    //     ); // smaller than maxSizeMB

    //     return compressedFile;

    //     //await uploadToServer(compressedFile); // write your own logic
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    // console.log(idfront[0].originFileObj);
    // console.log(idback[0].originFileObj);

    // console.log(selfie[0].originFileObj.name);

    // let selfie_compressed = await handleImageUpload(selfie[0].originFileObj);
    // let idfront_compressed = await handleImageUpload(idfront[0].originFileObj);
    // let idback_compressed = await handleImageUpload(idback[0].originFileObj);
    // console.log(selfie_compressed);
    // console.log(idfront_compressed);
    // console.log(idback_compressed);
    // return;
    // store images on bucket
    let storageRef = ref(storage, `/files/${selfie[0].originFileObj.name}`);
    let uploadTask = uploadBytesResumable(storageRef, selfie[0].originFileObj);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((selfie_bucket_uri) => {
          console.log("selfie_bucket_uri: " + selfie_bucket_uri);

          // uploading front id

          let storageRef1 = ref(
            storage,
            `/files/${idfront[0].originFileObj.name}`
          );
          let uploadTask1 = uploadBytesResumable(
            storageRef1,
            idfront[0].originFileObj
          );
          uploadTask1.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
            },
            (err) => console.log(err),
            () => {
              getDownloadURL(uploadTask1.snapshot.ref).then(
                (idfront_bucket_uri) => {
                  console.log("id front bucket: " + idfront_bucket_uri);

                  // start of back id

                  let storageRef2 = ref(
                    storage,
                    `/files/${idback[0].originFileObj.name}`
                  );
                  let uploadTask2 = uploadBytesResumable(
                    storageRef2,
                    idback[0].originFileObj
                  );
                  uploadTask2.on(
                    "state_changed",
                    (snapshot) => {
                      const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                      );
                    },
                    (err) => console.log(err),
                    () => {
                      getDownloadURL(uploadTask2.snapshot.ref).then(
                        (idback_bucket_uri) => {
                          console.log("back id bucket: " + idback_bucket_uri);

                          // push to server
                          const formData = new FormData();
                          formData.append("name", name);
                          formData.append("id_number", idNo);
                          formData.append("date_of_birth", selectedDate);
                          formData.append("phone", phone);
                          formData.append("payload", JSON.stringify(payload));
                          formData.append("town", town);
                          formData.append("physical_address", physicalAdress);
                          formData.append("route", route);
                          formData.append("route_code", route_code);
                          formData.append("gender", gender);
                          formData.append("selfie_uri", selfie_bucket_uri); //selfie[0].originFileObj);
                          formData.append("idfront_uri", idfront_bucket_uri); //idfront[0].originFileObj);
                          formData.append("backfront_uri", idback_bucket_uri); //idback[0].originFileObj);
                          formData.append("is_complete_finance", false);
                          formData.append("is_verified", false);
                          formData.append("sacco", sacco);
                          formData.append("next_of_kin_name", kinName);
                          formData.append("next_of_kin_contact", kinContact);
                          formData.append("cover_cost", cover_price);
                          formData.append("cover_type", pack);

                          axios
                            .post(`${BASE_URI}/add/client`, formData)
                            .then((res) => {
                              setLoading(false);

                              console.log(res.data);

                              if (!res.data.success) {
                                openErrorNotification(
                                  "Server Error: Please try again or Contact Admin if the error persists"
                                );

                                return;
                              }
                              setTimeout(() => {
                                setLoading(false);
                                openNotification();
                                setName("");
                                setIdNO("");
                                setDOB("");
                                setPhone("");
                                setTown("");
                                setphysicalAdress("");
                                setRoute("");
                                setroute_code("");
                                setSelfie("");
                                setidfront("");
                                setidback("");
                                setSacco("");
                                setkinName("");
                                setkinContact("");
                                setGender("");
                                setPack("");
                              }, 1000);
                            })
                            .catch((err) => {
                              setLoading(false);

                              setTimeout(() => {
                                setLoading(false);
                              }, 1000);

                              openErrorNotification(err.message);

                              console.log(err);
                            });
                        }
                      );
                    }
                  );

                  // end of back id
                }
              );
            }
          );

          // end of front id
        });
      }
    );
  };

  const [name, setName] = useState("");
  const [idNo, setIdNO] = useState("");
  const [dob, setDOB] = useState("");
  const [phone, setPhone] = useState("");
  const [town, setTown] = useState("Other");
  const [physicalAdress, setphysicalAdress] = useState("");
  const [route, setRoute] = useState("");
  const [route_code, setroute_code] = useState("");
  const [selfie, setSelfie] = useState();
  const [idfront, setidfront] = useState();
  const [idback, setidback] = useState();
  const [loading, setLoading] = useState(false);
  const [sacco, setSacco] = useState("");
  const [kinName, setkinName] = useState("");
  const [kinContact, setkinContact] = useState("");
  const [gender, setGender] = useState("Male");
  const [modal, setModal] = useState(false);

  const [pack, setPack] = useState("Myself only [60/day or 420/week]");

  // subscription states
  const [one_member_pack_name, setone_member_pack_name] = useState("");
  const [one_member_pack_relationship, setone_member_pack_relationship] =
    useState("SPOUSE");
  // 2 packs states
  const [two_member_pack_name, settwo_member_pack_name] = useState("");
  const [two_member_pack_relationship, settwo_member_pack_relationship] =
    useState("SPOUSE");
  const [two_member_pack_name2, settwo_member_pack_name2] = useState("");
  const [two_member_pack_relationship2, settwo_member_pack_relationship2] =
    useState("SPOUSE");
  // 3 packs states
  const [three_member_pack_name, setthree_member_pack_name] = useState("");
  const [three_member_pack_relationship, setthree_member_pack_relationship] =
    useState("SPOUSE");
  const [three_member_pack_name2, setthree_member_pack_name2] = useState("");
  const [three_member_pack_relationship2, setthree_member_pack_relationship2] =
    useState("SPOUSE");
  const [three_member_pack_name3, setthree_member_pack_name3] = useState("");
  const [three_member_pack_relationship3, setthree_member_pack_relationship3] =
    useState("SPOUSE");

  // 4 packs states
  const [four_member_pack_name, setfour_member_pack_name] = useState("");
  const [four_member_pack_relationship, setfour_member_pack_relationship] =
    useState("SPOUSE");
  const [four_member_pack_name2, setfour_member_pack_name2] = useState("");
  const [four_member_pack_relationship2, setfour_member_pack_relationship2] =
    useState("SPOUSE");
  const [four_member_pack_name3, setfour_member_pack_name3] = useState("");
  const [four_member_pack_relationship3, setfour_member_pack_relationship3] =
    useState("SPOUSE");
  const [four_member_pack_name4, setfour_member_pack_name4] = useState("");
  const [four_member_pack_relationship4, setfour_member_pack_relationship4] =
    useState("SPOUSE");

  // 5 packs states
  const [five_member_pack_name, setfive_member_pack_name] = useState("");
  const [five_member_pack_relationship, setfive_member_pack_relationship] =
    useState("SPOUSE");
  const [five_member_pack_name2, setfive_member_pack_name2] = useState("");
  const [five_member_pack_relationship2, setfive_member_pack_relationship2] =
    useState("SPOUSE");
  const [five_member_pack_name3, setfive_member_pack_name3] = useState("");
  const [five_member_pack_relationship3, setfive_member_pack_relationship3] =
    useState("SPOUSE");
  const [five_member_pack_name4, setfive_member_pack_name4] = useState("");
  const [five_member_pack_relationship4, setfive_member_pack_relationship4] =
    useState("SPOUSE");
  const [five_member_pack_name5, setfive_member_pack_name5] = useState("");
  const [five_member_pack_relationship5, setfive_member_pack_relationship5] =
    useState("SPOUSE");
  function onChangeDate(date, dateString) {
    setDOB(dateString);
  }
  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.open({
      message: "Success",
      description: "We have successfully created an account",
      icon: (
        <SmileOutlined
          style={{
            color: "green",
          }}
        />
      ),
    });
  };

  const openErrorNotification = (message) => {
    api.open({
      message: "Whoops",
      description: message,
      icon: (
        <SmileOutlined
          style={{
            color: "red",
          }}
        />
      ),
    });
  };

  // return <div></div>;
  const formatDate = (date) => {
    // formats a JS date to 'yyyy-mm-dd'
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [selectedDate, setSelectedDate] = useState("1900-01-01");

  return (
    <form className="md:w-1/4 w-[90%] mx-auto my-[2rem] ">
      {contextHolder}
      <Modal
        title=""
        closeIcon={false}
        centered={true}
        open={modal}
        footer={null}
      >
        {!loading ? (
          <div className="flex flex-col items-center justify-center">
            <Image height={50} width={50} src={require("../success.png")} />

            <p className="text-[1.3rem]">Registration successfull!</p>
          </div>
        ) : (
          <div className="flex">
            <Spin />
            <p className="ml-4 font-bold">Please wait...</p>
          </div>
        )}
      </Modal>
      <p className="font-bold text-[1.5rem]">Add your Information</p>
      <div class="mb-6">
        <label for="text" class="block mb-2 text-sm font-medium text-gray-900 ">
          Your Name
        </label>
        <input
          value={name}
          name={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          id="text"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          placeholder="Njoroge Kimani Doe"
          required
        />
      </div>
      <div className="flex space-x-1 w-full">
        <div class="mb-6">
          <label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Your ID Number
          </label>
          <input
            value={idNo}
            name={idNo}
            onChange={(e) => setIdNO(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="00291927"
            required
          />
        </div>
        <div class="mb-6">
          <label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Date Of Birth
          </label>
          {/* <DatePicker
            onChange={onChangeDate}
            width={20}
            className="md:w-[15rem]"
            placeholder="Select Date of Birth"
            size="large"
          /> */}
          <DropdownDate
            // startDate={
            //   // optional, if not provided 1900-01-01 is startDate
            //   "2012-01-01" // 'yyyy-mm-dd' format only
            // }
            // endDate={
            //   // optional, if not provided current date is endDate
            //   "2013-12-31" // 'yyyy-mm-dd' format only
            // }
            selectedDate={
              // optional
              selectedDate // 'yyyy-mm-dd' format only
            }
            onDateChange={(date) => {
              // optional
              console.log(date);
              setSelectedDate(formatDate(date));
            }}
          />
        </div>
      </div>
      <div class="mb-6">
        <label for="text" class="block mb-2 text-sm font-medium text-gray-900 ">
          Phone Number
        </label>
        <input
          type="number"
          value={phone}
          name={phone}
          onChange={(e) => setPhone(e.target.value)}
          id="text"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          placeholder="0729192723"
          required
        />
      </div>
      <div class="mb-6">
        <label for="text" class="block mb-2 text-sm font-medium text-gray-900 ">
          Gender
        </label>

        <Select
          defaultValue="Male"
          style={{
            width: "100%",
          }}
          onChange={(value) => {
            setGender(value);
          }}
          options={[
            {
              value: "Male",
              label: "Male",
            },
            {
              value: "Female",
              label: "Female",
            },
          ]}
        />
      </div>
      <div class="mb-6">
        <label for="text" class="block mb-2 text-sm font-medium text-gray-900 ">
          Town
        </label>
        {/* <input
          value={town}
          name={town}
          onChange={(e) => setTown(e.target.value)}
          type="text"
          id="text"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          placeholder="Nairobi"
          required
        /> */}

        <Select
          defaultValue="Other"
          style={{
            width: "100%",
          }}
          onChange={(value) => {
            setTown(value);
          }}
          options={[
            {
              value: "Nairobi",
              label: "Nairobi",
            },
            {
              value: "Other",
              label: "Other",
            },
          ]}
        />
      </div>
      <div class="mb-6">
        <label
          for="password"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Route
        </label>
        {town.includes("Nairobi") ||
        town.includes("NAIROBI") ||
        town.includes("nairobi") ? (
          <Select
            defaultValue="Select Corridor (Nairobi)"
            style={{
              width: "100%",
            }}
            onChange={(value) => {
              setRoute(value);
            }}
            options={[
              {
                value: "Juja Road",
                label: "Juja Road",
              },
              {
                value: "Mombasa Road",
                label: "Mombasa Road",
              },
              {
                value: "Waiyaki Way",
                label: "Waiyaki Way",
              },
              {
                value: "Jogoo Road",
                label: "Jogoo Road",
              },
              {
                value: "Lan'gata Road",
                label: "Lan'gata Road",
              },

              {
                value: "Thika Road",
                label: "Thika Road",
              },

              {
                value: "Kangundo Road",
                label: "Kangundo Road",
              },
              {
                value: "Ngong Road",
                label: "Ngong Road",
              },
              {
                value: "Limuru Road",
                label: "Limuru Road",
              },
              {
                value: "Argwings Kodhek",
                label: "Argwings Kodhek",
              },
              {
                value: "Industrial Area",
                label: "Industrial Area",
              },
            ]}
          />
        ) : (
          <div>
            <input
              value={route}
              name={route}
              onChange={(e) => setRoute(e.target.value)}
              type="text"
              placeholder="Thika Road"
              id="text"
              class="shadow-sm  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              required
            />
          </div>
        )}
      </div>
      <div class="mb-6">
        <label for="text" class="block mb-2 text-sm font-medium text-gray-900 ">
          Sacco
        </label>
        <input
          type="text"
          value={sacco}
          onChange={(e) => setSacco(e.target.value)}
          id="text"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          placeholder="Apple Agencies"
          required
        />
      </div>
      <div class="mb-6">
        <label for="text" class="block mb-2 text-sm font-medium text-gray-900 ">
          Registration Code
        </label>
        <input
          type="text"
          value={route_code}
          onChange={(e) => setroute_code(e.target.value)}
          id="text"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          placeholder="BC-56"
          required
        />
      </div>
      <div class="mb-6">
        <label for="text" class="block mb-2 text-sm font-medium text-gray-900 ">
          Next of Kin Name
        </label>
        <input
          type="text"
          value={kinName}
          onChange={(e) => setkinName(e.target.value)}
          id="text"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          placeholder="Annet Kelyn Mwaura"
          required
        />
      </div>
      <div class="mb-6">
        <label for="text" class="block mb-2 text-sm font-medium text-gray-900 ">
          Next of Kin Contact
        </label>
        <input
          type="number"
          value={kinContact}
          onChange={(e) => setkinContact(e.target.value)}
          id="text"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          placeholder="0712345678"
          required
        />
      </div>
      <div class="mb-6">
        <label
          for="repeat-password"
          class="block mb-2 text-sm font-medium text-gray-900"
        >
          Physical Address
        </label>
        <input
          value={physicalAdress}
          name={physicalAdress}
          onChange={(e) => setphysicalAdress(e.target.value)}
          type="text"
          placeholder="Moi Avenue Opp ****"
          id="repeat-password"
          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          required
        />
      </div>
      <div class="mb-6">
        <label for="text" class="block mb-2 text-sm font-medium text-gray-900 ">
          How many family members do you want to cover?
        </label>
        <Select
          defaultValue="Myself only [60/day or 420/week]"
          style={{
            width: "100%",
          }}
          onChange={(value) => {
            setPack(value);
          }}
          options={[
            {
              value: "Myself only [60/day or 420/week]",
              label: "Myself only [60/day or 420/week]",
            },
            {
              value: "Me and 1 Other Member [85/day or 595/week]",
              label: "Me and 1 Other Member [85/day or 595/week]",
            },
            {
              value: "Me and 2 Other Members [100/day or 700/week]",
              label: "Me and 2 Other Members [100/day or 700/week]",
            },
            {
              value: "Me and 3 Other Membes [120/day or 840/week]",
              label: "Me and 3 Other Members [120/day or 840/week]",
            },
            {
              value: "Me and 4 Other Members [130/day or 920/week]",
              label: "Me and 4 Other Members [130day or 920week]",
            },
            {
              value: "Me and 5 Other Members [140/day or 980/week]",
              label: "Me and 5 Other Members [140/day or 980/week]",
            },
          ]}
        />
      </div>
      {pack == "Me and 1 Other Member [85/day or 595/week]" && (
        <div class="mb-6">
          <label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Please insert the name of the family member you want to cover:
          </label>
          <input
            value={one_member_pack_name}
            onChange={(e) => setone_member_pack_name(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />
          {/* <input
            value={one_member_pack_relationship}
            onChange={(e) => setone_member_pack_relationship(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Relationship"
            required
          /> */}
          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setone_member_pack_relationship(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
        </div>
      )}
      {pack == "Me and 2 Other Members [100/day or 700/week]" && (
        <div class="mb-6">
          <label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Please insert the name of the family members you want to cover:
          </label>
          <input
            value={two_member_pack_name}
            onChange={(e) => settwo_member_pack_name(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              settwo_member_pack_relationship(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
          <input
            value={two_member_pack_name2}
            onChange={(e) => settwo_member_pack_name2(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              settwo_member_pack_relationship2(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
        </div>
      )}
      {pack == "Me and 3 Other Membes [120/day or 840/week]" && (
        <div class="mb-6">
          <label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Please insert the name of the family members you want to cover:
          </label>
          <input
            value={three_member_pack_name}
            onChange={(e) => setthree_member_pack_name(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setthree_member_pack_relationship(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
          <input
            value={three_member_pack_name2}
            onChange={(e) => setthree_member_pack_name2(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setthree_member_pack_relationship2(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
          <input
            value={three_member_pack_name3}
            onChange={(e) => setthree_member_pack_name3(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setthree_member_pack_relationship3(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
        </div>
      )}
      {pack == "Me and 4 Other Members [130/day or 920/week]" && (
        <div class="mb-6">
          <label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Please insert the name of the family members you want to cover:
          </label>
          <input
            value={four_member_pack_name}
            onChange={(e) => setfour_member_pack_name(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setfour_member_pack_relationship(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />

          <input
            value={four_member_pack_name2}
            onChange={(e) => setfour_member_pack_name2(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setfour_member_pack_relationship2(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
          <input
            value={four_member_pack_name3}
            onChange={(e) => setfour_member_pack_name3(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setfour_member_pack_relationship3(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
          <input
            value={four_member_pack_name4}
            onChange={(e) => setfour_member_pack_name4(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setfour_member_pack_relationship4(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
        </div>
      )}
      {pack == "Me and 5 Other Members [140/day or 980/week]" && (
        <div class="mb-6">
          <label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Please insert the name of the family members you want to cover:
          </label>
          <input
            value={five_member_pack_name}
            onChange={(e) => setfive_member_pack_name(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setfive_member_pack_relationship(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />

          <input
            value={five_member_pack_name2}
            onChange={(e) => setfive_member_pack_name2(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setfive_member_pack_relationship2(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
          <input
            value={five_member_pack_name3}
            onChange={(e) => setfive_member_pack_name3(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setfive_member_pack_relationship3(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
          <input
            value={five_member_pack_name4}
            onChange={(e) => setfive_member_pack_name4(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setfive_member_pack_relationship4(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
          <input
            value={five_member_pack_name5}
            onChange={(e) => setfive_member_pack_name5(e.target.value)}
            type="text"
            id="text"
            class="shadow-sm mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            placeholder="Name"
            required
          />

          <Select
            defaultValue="SPOUSE"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            onChange={(value) => {
              setfive_member_pack_relationship5(value);
            }}
            options={[
              {
                value: "SPOUSE",
                label: "SPOUSE",
              },
              {
                value: "CHILD",
                label: "CHILD",
              },
            ]}
          />
        </div>
      )}
      <div class="mb-6">
        <label
          for="repeat-password"
          class="block mb-2 text-sm font-medium text-gray-900"
        >
          Upload Selfie
        </label>
        <UploadSelfie file={setSelfie} />
      </div>
      <div class="mb-6">
        <label
          for="repeat-password"
          class="block mb-2 text-sm font-medium text-gray-900"
        >
          Take ID Photo (Front)
        </label>
        <UploadSelfie file={setidfront} />
      </div>{" "}
      <div class="mb-6">
        <label
          for="repeat-password"
          class="block mb-2 text-sm font-medium text-gray-900"
        >
          Take ID Photo (Back)
        </label>
        <UploadSelfie file={setidback} />
      </div>
      <div class="flex items-start mb-6">
        <div class="flex items-center h-5">
          <input
            id="terms"
            type="checkbox"
            value=""
            class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
            required
          />
        </div>
        <label for="terms" class="ml-2 text-sm font-medium text-gray-900">
          I agree with the{" "}
          <a href="#" class="text-blue-600 hover:underline">
            terms and conditions
          </a>
        </label>
      </div>
      {loading ? (
        <button
          htmlType="submit"
          class="text-white bg-blue-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Registering...
        </button>
      ) : (
        <button
          onClick={addClient}
          htmlType="submit"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Register new account
        </button>
      )}
    </form>
  );
}

import { Image } from "antd";
import "./App.css";
import ClientList from "./components/ClientList";
import ClientInputForm from "./components/ClientsInputForm";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="flex flex-col justify-center  items-center">
      <div>
        <Image height={200} src={require("./logo.jpg")} />
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ClientInputForm />} />
          <Route
            path="/dataportal"
            element={<ClientList />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URI } from "../BASE_URI";
import UploadSelfie from "./UploadSelfie";
import { Button, Modal, Popconfirm, message } from "antd";
import FileUploadComponent from "./FileUploadComponent";

export default function ClientList() {
  const [clients, setClients] = useState([]);
  const [resetclients, setResetClients] = useState([]);
  const [loginStatus, setLoginStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modal, setModal] = useState(false);
  const [link, setlink] = useState("");
  const [activeClient, setActiveClient] = useState();
  const [totals, settotals] = useState(0);
  // const [loading, setActiveClient] = useState();



  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    axios
      .get(`${BASE_URI}/all/clients`)
      .then((res) => {
        const compareByFirstname = (a, b) => {
          const firstnameA = a.name.toLowerCase();
          const firstnameB = b.name.toLowerCase();

          if (firstnameA < firstnameB) {
            return -1;
          }
          if (firstnameA > firstnameB) {
            return 1;
          }
          return 0;
        };
        let sorted_data = res.data.data.sort(compareByFirstname);
        setClients(sorted_data);
        setResetClients(sorted_data);
        console.log(sorted_data);
        // GET Total amount
        let total = 0;
        res.data.data.map((client) => {
          if (client.cover_type == "Myself only [60/day or 420/week]") {
            return (total = total + 17925);
          } else if (
            client.cover_type == "Me and 1 Other Member [85/day or 595/week]"
          ) {
            return (total = total + 24867);
          } else if (
            client.cover_type == "Me and 2 Other Members [100/day or 700/week]"
          ) {
            return (total = total + 29392);
          } else if (
            client.cover_type == "Me and 3 Other Membes [120/day or 840/week]"
          ) {
            return (total = total + 35366);
          } else if (
            client.cover_type == "Me and 4 Other Members [130/day or 920/week]"
          ) {
            return (total = total + 38383);
          } else if (
            client.cover_type == "Me and 5 Other Members [140/day or 980/week]"
          ) {
            return (total = total + 41279);
          }
        });
        console.log('totals')

        console.log(total);
        settotals(total);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const login = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${BASE_URI}/login`, {
        email, //: "invencorp@optimahealth.co.ke",
        password, //: "&3r3BDk8NcGWkx%e",
      })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setLoading(false);
          setLoginStatus(true);
        } else {
          setLoading(false);
          setLoginStatus(false);
          setError(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoginStatus(false);
        setError(true);

        console.log(err);
      });
  };

  if (!loginStatus) {
    return (
      <form className="md:w-1/3 mt-[2rem]">
        {error && (
          <div className="bg-red-300 my-5 flex justify-between py-2 px-3 rounded-md text-gray-100">
            {" "}
            <p>You've used invalid login credentials</p>
            <p className="cursor-pointer" onClick={() => setError(false)}>
              X
            </p>
          </div>
        )}
        <div class="mb-6">
          <label
            for="large-input"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            id="large-input"
            class="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 "
          />
        </div>
        <div class="mb-6">
          <label
            for="large-input"
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            Password
          </label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            id="large-input"
            class="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 "
          />
        </div>
        {loading ? (
          <button
            onClick={login}
            class="text-white flex justify-center items-center bg-blue-300 hover:bg-blue-800 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          >
            <div role="status">
              <svg
                aria-hidden="true"
                class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
            <p>Signing In...</p>
          </button>
        ) : (
          <button
            onClick={login}
            class="text-white w-full bg-blue-700 hover:bg-blue-800  font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center "
          >
            Sign In
          </button>
        )}
      </form>
    );
  }

  return (
    <div class="relative overflow-x-auto mx-auto w-5/6 mt-[2rem] border">
      {contextHolder}
      <input
        placeholder="Search by ID"
        className="px-[1rem] py-[0.5rem] border"
        onChange={(e) => {
          //

          if (e.target.value == "") {
            console.log(resetclients);
            setClients(resetclients);
            return;
          }
          //
          let result = clients.filter((element) =>
            element.route_code.includes(e.target.value)
          );
          setClients(result);
        }}
      />
      <a
        href="https://phindor.africa/insuarance-api/public/api/download"
        style={{
          backgroundColor: "rgba(26, 93, 171)",
          width: 220,
          cursor: "pointer",
          color: "#fff",
          borderRadius: 20,
          marginTop: 20,
          display: "flex",
          justifyContent: "center",

          alignItems: "center",
        }}
      >
        Export Data (CSV)
      </a>
      {/* {totals} */}
     TOTALS KES {new Intl.NumberFormat().format(totals)}

      <table class="w-full text-sm text-left text-gray-500 ">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 border-b">
          <tr>
            <th scope="col" class="px-6 py-3">
              ID
            </th>
            <th scope="col" class="px-6 py-3">
              Name
            </th>
            <th scope="col" class="px-6 py-3">
              ID Number
            </th>
            <th scope="col" class="px-6 py-3">
              Date Of Birth
            </th>
            <th scope="col" class="px-6 py-3">
              Gender
            </th>
            <th scope="col" class="px-6 py-3">
              Phone Number
            </th>
            <th scope="col" class="px-6 py-3">
              Town
            </th>
            <th scope="col" class="px-6 py-3">
              Route
            </th>
            <th scope="col" class="px-6 py-3">
              Cover Persons
            </th>
            <th scope="col" class="px-6 py-3">
              Cover Amount
            </th>
            <th scope="col" class="px-6 py-3">
              Registration Code
            </th>
            <th scope="col" class="px-6 py-3">
              Sacco
            </th>
            <th scope="col" class="px-6 py-3">
              Next Of Kin Name
            </th>{" "}
            <th scope="col" class="px-6 py-3">
              Next Of Kin Contact
            </th>
            <th scope="col" class="px-6 py-3">
              Physical Address
            </th>
            <th scope="col" class="px-6 py-3">
              Selfie
            </th>
            <th scope="col" class="px-6 py-3">
              Amount
            </th>
            <th scope="col" class="px-6 py-3">
              Id Front
            </th>{" "}
            <th scope="col" class="px-6 py-3">
              Id Back
            </th>
            <th scope="col" class="px-6 py-3">
              Verified
            </th>{" "}
            <th scope="col" class="px-6 py-3">
              Registered On
            </th>
            <th scope="col" class="px-6 py-3">
              Account Verified On
            </th>
            <th scope="col" class="px-6 py-3">
              KRA PIN
            </th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client, i) => {
            return (
              <tr class="bg-white border-b">
                <td class="px-6 py-4">{i + 1}</td>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  {client.name}
                </th>
                <td class="px-6 py-4">{client.id_number}</td>
                <td class="px-6 py-4">{client.date_of_birth}</td>
                <td class="px-6 py-4">{client.gender}</td>
                <td class="px-6 py-4">{client.phone}</td>
                <td class="px-6 py-4">{client.town}</td>
                <td class="px-6 py-4">{client.route}</td>
                <td class="px-6 py-4">
                  {(function getCoverNo() {
                    if (
                      client.cover_type == "Myself only [60/day or 420/week]"
                    ) {
                      return 1;
                    } else if (
                      client.cover_type ==
                      "Me and 1 Other Member [85/day or 595/week]"
                    ) {
                      return 2;
                    } else if (
                      client.cover_type ==
                      "Me and 2 Other Members [100/day or 700/week]"
                    ) {
                      return 3;
                    } else if (
                      client.cover_type ==
                      "Me and 3 Other Membes [120/day or 840/week]"
                    ) {
                      return 4;
                    } else if (
                      client.cover_type ==
                      "Me and 4 Other Members [130/day or 920/week]"
                    ) {
                      return 5;
                    } else if (
                      client.cover_type ==
                      "Me and 5 Other Members [140/day or 980/week]"
                    ) {
                      return 6;
                    }
                  })()}
                </td>
                <td class="px-6 py-4">
                  {(function getCoverNo() {
                    if (
                      client.cover_type == "Myself only [60/day or 420/week]"
                    ) {
                      return "[60/day or 420/week]";
                    } else if (
                      client.cover_type ==
                      "Me and 1 Other Member [85/day or 595/week]"
                    ) {
                      return "[85/day or 595/week]";
                    } else if (
                      client.cover_type ==
                      "Me and 2 Other Members [100/day or 700/week]"
                    ) {
                      return "[100/day or 700/week]";
                    } else if (
                      client.cover_type ==
                      "Me and 3 Other Membes [120/day or 840/week]"
                    ) {
                      return "[120/day or 840/week]";
                    } else if (
                      client.cover_type ==
                      "Me and 4 Other Members [130/day or 920/week]"
                    ) {
                      return "[130/day or 920/week]";
                    } else if (
                      client.cover_type ==
                      "Me and 5 Other Members [140/day or 980/week]"
                    ) {
                      return "[140/day or 980/week]";
                    }
                  })()}
                </td>
                <td class="px-6 py-4">{client.route_code}</td>
                <td class="px-6 py-4">{client.sacco}</td>
                <td class="px-6 py-4">{client.next_of_kin_name}</td>
                <td class="px-6 py-4">{client.next_of_kin_contact}</td>
                <td class="px-6 py-4">{client.physical_address}</td>
                <td class="px-6 py-4">
                  <UploadSelfie image={client.selfie_uri} />
                </td>
                <td class="px-6 py-4">
                  {(function getCoverNo() {
                    if (
                      client.cover_type == "Myself only [60/day or 420/week]"
                    ) {
                      return "KES 17,925";
                    } else if (
                      client.cover_type ==
                      "Me and 1 Other Member [85/day or 595/week]"
                    ) {
                      return "KES 24,867";
                    } else if (
                      client.cover_type ==
                      "Me and 2 Other Members [100/day or 700/week]"
                    ) {
                      return "KES 29,392";
                    } else if (
                      client.cover_type ==
                      "Me and 3 Other Membes [120/day or 840/week]"
                    ) {
                      return "KES 35,366";
                    } else if (
                      client.cover_type ==
                      "Me and 4 Other Members [130/day or 920/week]"
                    ) {
                      return "KES 38,383";
                    } else if (
                      client.cover_type ==
                      "Me and 5 Other Members [140/day or 980/week]"
                    ) {
                      return "KES 41,279";
                    }
                  })()}
                </td>
                <td class="px-6 py-4">
                  <UploadSelfie image={client.idfront_uri} />
                </td>{" "}
                <td class="px-6 py-4">
                  <UploadSelfie image={client.backfront_uri} />
                </td>
                <td class="px-6 py-4">
                  <div>
                    {client.is_verified == 1 ? (
                      <div className="text-green-700 font-bold">Verified</div>
                    ) : (
                      <Popconfirm
                        title="Verify Account"
                        description="Are you sure to verify this account?"
                        onConfirm={(e) => {
                          axios
                            .put(`${BASE_URI}/update/client/${client.id}`, {
                              is_verified: true,
                            })
                            .then((res) => {
                              console.log(res.data);
                              if (res.data.success) {
                                message.success(
                                  "Account verification request successfully sent"
                                );
                                window.location.reload(false);
                              } else {
                                message.error("Account verification failed");
                              }
                            })
                            .catch((err) => {
                              message.error("Account verification failed");
                              console.log(err);
                            });
                          // console.log(e);
                        }}
                        onCancel={(e) => {
                          console.log(e);
                          message.error("You can verify this account later!");
                        }}
                        okText="Verify"
                        cancelText="Not Now"
                      >
                        <div
                          onClick={() => {}}
                          className="text-red-500 cursor-pointer font-bold"
                        >
                          Not Verified
                        </div>
                      </Popconfirm>
                    )}
                  </div>
                </td>
                <td class="px-6 py-4">
                  {new Date(client.created_at).toDateString()}
                </td>
                <td class="px-6 py-4">
                  {new Date(client.created_at).toDateString()}
                </td>
                <td class="px-6 py-4">
                  {client.kra != null ? (
                    <UploadSelfie image={client.kra} />
                  ) : (
                    <div
                      onClick={() => {
                        setModal(true);
                        setActiveClient(client.id);
                        // console.log(client)
                      }}
                      className="bg-orange-500 px-5 text-white py-3 rounded-md cursor-pointer"
                    >
                      Add KRA PIN
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
        <Modal open={modal} onCancel={() => setModal(false)} footer={null}>
          <p>Add KRA PIN</p>
          <FileUploadComponent setlink={setlink} />
          {!loading ? (
            <div
              onClick={() => {
                setLoading(true);
                axios
                  .post(
                    `https://phindor.africa/insuarance-api/public/api/save_kra_doc/${activeClient}`,
                    {
                      kra_doc: link,
                    }
                  )
                  .then((res) => {
                    setLoading(false);

                    if (res.data.success) {
                      setModal(false);
                      messageApi.success("successfully added KRA PIN document");
                    } else {
                      messageApi.error(
                        "We encountered an error while uploading"
                      );
                    }
                    console.log(res.data);
                  })
                  .catch((e) => {
                    setLoading(false);

                    console.log(e);
                  });
              }}
              className="bg-green-500 px-5 py-3 w-fit rounded-[3rem] text-white cursor-pointer"
              // onClick={() => console.log(link)}
            >
              Save Details
            </div>
          ) : (
            <div className="bg-green-300 px-5 py-3 w-fit rounded-[3rem] text-white cursor-pointer">
              Saving...
            </div>
          )}
        </Modal>
      </table>
    </div>
  );
}
